.footer {
    width: 100%;
    background-color: transparent;
    display: flex;
    font-size: map_get($font-size, 13);
    font-weight: map_get($font-weight, semibold);
    line-height: 1.54;
    color: $footer-text-color;

    @media(min-width: $width-mobile-sm) {
        flex-direction: column;
        padding: 20px 20px 15px;
    }

    @media(min-width: $width-tablet) {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 20px;
    }

    @media(min-width: $width-desktop) {
        padding: 20px 0;
    }
    &__icons img{
        -webkit-transition : -webkit-filter 250ms linear
    }

    &__icons img:hover{
        filter: brightness(120%);
    }

    &__icons, &__links {
        display: flex;
    }

    &__icons, &__copyright {
        justify-content: center;
        align-items: center;
    }

    &__info {
        display: flex;
        flex-direction: column;

        @media(min-width: $width-mobile) {
            width: 100%;
        }

        @media(min-width: $width-tablet) {
            width: 50%;
        }
    }

    &__links {
        justify-content: space-between;

        @media (min-width: $width-mobile-sm) {
            flex-direction: column;
            order: 1;
        }

        @media (min-width: $width-mobile) {
            flex-direction: row;
            justify-content: center;
            order: 2;
        }

        @media (min-width: $width-tablet) {
            justify-content: flex-end;
        }

        a {
            text-decoration: none;
            font-size: map_get($font-size, 13);
            font-weight: map_get($font-weight, semibold);
            line-height: 1.54;
            color: $footer-text-color;
            white-space: nowrap;
            transition: $transition;

            @media (min-width: $width-mobile-sm) {
                text-align: center;
            }

            @media (min-width: $width-mobile) {
                &:first-of-type {
                    text-align: left;
                    margin-right: 10px;
                }
                &:last-of-type {
                    text-align: right;
                }
            }

            @media(min-width: $width-tablet) {
                margin-left: 10px;
                width: auto;

                &:first-of-type {
                    text-align: right;
                }
            }

            &:hover {
                color: $color-white;
            }
        }
    }

    &__icon {
        display: block;
        height: 20px;
        width: 20px;
        margin-right: 30px;
        cursor: pointer;

        &:last-of-type {
            margin-right: 0;
        }
    }

    &__icons {
        @media(min-width: $width-mobile-sm) {
            margin-bottom: 15px;
        }

        @media(min-width: $width-tablet) {
            margin-bottom: 0;
        }
    }

    &__copyright {
        display: inline-block;

        @media(min-width: $width-mobile-sm) {
            order: 2;
        }

        @media(min-width: $width-tablet) {
            text-align: right;
            order: 1;
        }
    }
}
