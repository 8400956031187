$glide-class: 'glide' !default;
$glide-element-separator: '__' !default;
$glide-modifier-separator: '--' !default;

.#{$glide-class} {
    $this: &;

    $se: $glide-element-separator;
    $sm: $glide-modifier-separator;

    position: relative;
    width: 100%;
    box-sizing: border-box;

    * {
        box-sizing: inherit;
    }

    &#{$se}track {
        overflow: hidden;
        max-height: 100%;
    }

    &#{$se}slides {
        position: relative;
        width: 100%;
        list-style: none;
        backface-visibility: hidden;
        transform-style: preserve-3d;
        touch-action: pan-Y;
        overflow: hidden;
        padding: 0;
        white-space: nowrap;
        display: flex;
        flex-wrap: nowrap;
        will-change: transform;
        max-height: 100%;

        &#{$glide-modifier-separator}dragging {
            user-select: none;
        }
    }

    &#{$se}slide {
        width: 100%;
        height: 100%;
        flex-shrink: 0;
        white-space: normal;
        user-select: none;
        -webkit-touch-callout: none;
        -webkit-tap-highlight-color: transparent;
        border-radius: 0 0 8px 8px;

        a {
            user-select: none;
            -webkit-user-drag: none;
            -moz-user-select: none;
            -ms-user-select: none;
        }
    }

    &#{$se}arrows {
        -webkit-touch-callout: none;
        user-select: none;
        display: none;
    }

    &#{$se}bullets {
        -webkit-touch-callout: none;
        user-select: none;
        list-style: none;
        display: flex;
        width: 100%;
        justify-content: center;
        position: absolute;

        @media(min-width: $width-mobile-sm) {
            bottom: -20px;
        }

        @media(min-width: $width-laptop) {
            bottom: -40px;
        }
    }

    &#{$se}bullet {
        font-size: 0;
        width: 7px;
        height: 7px;
        background-color: transparent;
        border-radius: 50%;
        border: 1px solid $color-white;
        margin-right: 20px;
        cursor: pointer;

        &:hover {
            border: 2px solid $color-white;
        }

        &:focus {
            outline: none;
        }

        &:last-of-type {
            margin-right: 0;
        }

        &.glide__bullet--active {
            background-color: $color-white;
        }
    }

    &#{$sm}rtl {
        direction: rtl;
    }
}
