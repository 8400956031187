.cta {
    display: flex;

    @media(min-width: $width-mobile-sm) {
        flex-direction: column;
        justify-content: center;
        margin: 20px auto 0;
    }

    @media(min-width: $width-tablet) {
        margin: 30px auto 0;
    }

    @media(min-width: $width-laptop) {
        flex-direction: row;
        justify-content: space-between;
        margin: 50px auto 0;
    }

    @media(min-width: $width-desktop) {
        margin: 100px auto 10px;
        max-width: 1024px;
    }

    &__title,
    &__text {
        display: inline-block;
        width: 100%;
    }

    &__title {
        font-size: map_get($font-size, 18);
        font-weight: map_get($font-weight, semibold);
        color: $color-white;

        @media(min-width: $width-mobile-sm) {
            text-align: center;
        }

        @media(min-width: $width-laptop) {
            text-align: left;
            font-size: map_get($font-size, 36);
            font-weight: map_get($font-weight, bold);
            line-height: 1.17;
            max-width: 460px;
        }
    }

    &__text {
        display: inline-block;
        font-size: map_get($font-size, 16);
        color: $main-text-color;

        @media(min-width: $width-mobile-sm) {
            max-width: 270px;
            margin: 20px auto;
            text-align: center;
        }

        @media(min-width: $width-laptop) {
            max-width: 460px;
            margin: 20px 0;
            text-align: left;
        }
    }

    &__img {
        @media(min-width: $width-mobile-sm) {
            display: none;
        }

        @media(min-width: $width-laptop) {
            display: block;
            max-width: 460px;
        }

        img {
            display: block;
            width: 100%;
            height: auto;
        }
    }

    &__inner {
        display: flex;
        flex-direction: column;
    }

    &__btn {
        @media(min-width: $width-laptop) {
            margin-top: 40px;
        }
    }
}
