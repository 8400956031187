.category {
    text-align: left;
    display: flex;

    @media(min-width: $width-mobile-sm) {
        padding: 9px 20px 20px;
        flex-direction: column;
    }

    @media(min-width: $width-laptop) {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }

    @media(min-width: $width-desktop) {
        padding: 10px 0;
    }

    &__title {
        text-align: left;

        @media(min-width: $width-desktop) {
            font-size: map_get($font-size, 27);
        }
    }

    &__subtitle {
        margin-bottom: 10px;

        @media(min-width: $width-laptop) {
            font-size: map_get($font-size, 66);
        }
    }

    &__form {

        @media(min-width: $width-mobile-sm) {
            width: 100%;
            margin: 20px auto
        }

        @media(min-width: $width-tablet) {
            width: 70%;
            margin: 30px auto;
        }

        @media(min-width: $width-laptop) {
            width: 100%;
            max-width: 400px;
            margin: 20px 0;
        }
    }

    &__inner {
        @media(min-width: $width-mobile-sm) {
            width: 100%;
        }

        @media(min-width: $width-laptop) {
            width: 35%;
        }
    }

    &__input {
        font-family: 'SegoeUI', sans-serif;
        font-size: map_get($font-size, 14);
        font-weight: map_get($font-weight, semibold);
        color: $color-secondary;
        display: flex;
        align-items: center;
        height: 50px;
        border-radius: 25px;
        width: 100%;
        border: solid 1px $color-grey-light;
        background-color: $color-white;
        padding: 0 40px 0 15px;

        &-wrapper {
            position: relative;
        }

        &-icon {
            position: absolute;
            top: 15px;
            right: 15px;
            cursor: pointer;
        }
    }

    &__btns {
        display: flex;

        @media(min-width: $width-mobile-sm) {
            margin-top: 10px;
            justify-content: space-between;
        }
    }

    &__btn {

        @media(min-width: $width-mobile-sm) {
            width: calc(50% - 5px);
            margin: 0;
        }
    }

    &__desc {
        display: inline-block;
        width: 100%;
        font-size: map_get($font-size, 14);
        font-weight: map_get($font-weight, semibold);
        color: $category-text-color;
        margin-bottom: 15px;

        &:last-of-type {
            margin-bottom: 0;
        }
    }

    &__slider {
        background-color: $color-grey-light;
        position: relative;
        box-shadow: 0 30px 36px -10px $main-wrap-deep-shadow-color;
        border-radius: 8px;

        @media(min-width: $width-mobile-sm) {
            width: 100%;
            max-width: 400px;
            margin: 0 auto;
        }

        @media(min-width: $width-tablet) {
            max-width: 500px;
        }

        @media(min-width: $width-laptop) {
            margin: 0;
            max-width: 600px;
        }

        @media(min-width: $width-desktop) {
            max-width: 740px;
        }

        &-top {
            width: 100%;
            height: auto;
            margin-bottom: -3px;
        }

        &-item {
            max-height: 100%;
            max-width: 100%;
            display: block;
            height: auto;
        }
    }
}
