.login {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    @media(min-width: $width-mobile-sm) {
        padding: 0 10px 30px;
    }

    @media(min-width: $width-tablet) {
        padding: 0 0 50px 0;
    }

    @media(min-width: $width-laptop) {
        padding: 0 0 100px 0;
    }

    &__inner {
        width: 100%;
        height: 100%;
        border: 1px solid $color-grey-light;
        border-radius: 16px;
        background-color: $color-white;
        max-width: 420px;

        @media(min-width: $width-mobile-sm) {
            margin-top: 10px;
            padding: 20px 10px;
            max-height: 300px;
        }
        @media(min-width: $width-mobile) {
            padding: 20px 15px;
        }

        @media(min-width: $width-tablet) {
            margin-top: 20px;
            padding: 20px 30px;
            max-height: 250px;
        }
    }

    &__desc {
        display: inline-block;
        width: 100%;
        font-size: map_get($font-size, 12);
        font-weight: map_get($font-weight, semibold);
        color: $login-text-primary-color;
        margin-bottom: 20px;

        &:last-of-type {
            margin-bottom: 0;
        }

        &--bold {
            color: $login-text-secondary-color;
            font-weight: map_get($font-weight, bold);
        }
    }

    &__icon {
        margin-right: 5px;
    }

    &__form {
        position: relative;
    }

    &__btn {
        font-size: map_get($font-size, 14);
        width: 100%;
        max-width: 100%;
        margin: 20px auto 10px;

        @media(min-width: $width-mobile-sm) {
            max-height: 40px;
        }

        @media(min-width: $width-tablet) {
            max-height: 50px;
        }

        &-wrapper {
            display: block;
            position: absolute;
            width: 100%;
            height: 100%;

            &--hidden {
                width: 0;
                height: 0;
            }
        }
    }
}
