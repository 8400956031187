.checkbox {
    display: flex;
    align-items: center;
    position: relative;

    input {
        display: none;
    }

    &__link {
        text-decoration: none;
        margin: 0 2px;

        &:hover {
            opacity: .7;
        }
    }

    &__span {
        font-weight: map_get($font-weight, semibold);
        color: $login-text-primary-color;
        padding: 0 0 0 30px;
        text-align: left;

        @media(min-width: $width-mobile-sm) {
            font-size: map_get($font-size, 9);
        }

        @media(min-width: $width-tablet) {
            font-size: map_get($font-size, 10);
        }

        &:before, &:after {
            cursor: pointer;
        }

        &:before {
            content: '';
            display: block;
            position: absolute;
            left: 4px;
            top: 4px;
            width: 18px;
            height: 18px;
            border-radius: 4px;
            border: 1px dashed $color-primary;
        }

        &--error {
            &:before {
                border: 1px solid $color-tertiary;
            }
        }
    }

    input:not(:checked) + &__span {
        &:after {
            display: none;
        }
    }

    input:checked + &__span {
        &:before {
            border: 1px solid $color-grey-light;
        }

        &:after {
            content: url('/images/icons/check-primary.svg');
            display: block;
            position: absolute;
            left: 7px;
            top: 9px;
        }
    }
}
