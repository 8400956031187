$header-nav-icons: ('modifier': home,
        backgroundPosition: 0 0,
        backgroundPositionActive: 0 104px,
),
('modifier': audience,
        backgroundPosition: 0 -26px,
        backgroundPositionActive: 0 78px,
),
('modifier': features,
        backgroundPosition: 0 -52px,
        backgroundPositionActive: 0 52px,
),
('modifier': pricing,
        backgroundPosition: 0 -78px,
        backgroundPositionActive: 0 26px
);

.header-nav {
    @media(min-width: $width-mobile-sm) {
        display: none;
    }

    @media(min-width: $width-laptop) {
        display: flex;
        justify-content: space-between;
        width: 30%;
        height: 100%;
    }

    &__wrap {
        position: relative;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;

        &:hover {
            .header-nav__menu {
                display: flex;
            }
        }
    }

    &__item {
        text-decoration: none;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        transition: $transition;
        color: $color-grey-primary;

        &:before {
            display: block;
            height: 26px;
            width: 26px;
            content: '';
            background-size: auto 100%;
            background: url('/images/icons/header/header-nav-sprite.svg');
        }

        @each $icon in $header-nav-icons {
            &.icon--#{map-get($icon, modifier)} {
                &:before {
                    background-position: map-get($icon, backgroundPosition)
                }
            }
        }

        &:hover, &--active {
            color: var(--appSecondaryColor);

            @each $icon in $header-nav-icons {
                &.icon--#{map-get($icon, modifier)} {
                    &:before {
                        background-position: map-get($icon, backgroundPositionActive);
                    }
                }
            }
        }

        &-span {
            font-size: map_get($font-size, 11);
            font-weight: map_get($font-weight, semibold);
            letter-spacing: 0.28px;
            text-align: center;
            text-transform: uppercase;
        }
    }

    &__menu {
        display: none;
        position: absolute;
        width: 220px;
        flex-direction: column;
        background-color: $color-white;
        top: 59px;
        transition: $transition;
        left: -82px;
        border: 1px solid $color-grey-light;
        border-top: none;
        z-index: 10;
        border-radius: 0 0 8px 8px;
        padding: 5px 0;

        &:hover {
            display: flex;
        }
    }

    &__subItem {
        text-decoration: none;
        text-align: left;
        background-color: $color-white;
        padding: 5px 10px;
        font-size: map_get($font-size, 14);
        font-weight: map_get($font-weight, semibold);
        color: $color-grey-primary;

        &:last-of-type {
            border-radius: 0 0 8px 8px;
        }

        &:hover,
        &--active {
            color: var(--appSecondaryColor);
        }
    }
}
