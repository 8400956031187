.mobile-menu {
    display: flex;
    height: calc(100vh - 60px);
    flex-direction: column;
    position: fixed;
    top: 60px;
    transition: $transition;
    background-color: $color-white;
    max-width: 375px;
    z-index: 10;
    padding: 60px 0;

    @media(min-width: 0) {
        right: -100%;
        width: 100%;
    }

    @media(min-width: $width-laptop) {
        display: none;
    }

    &--opened {
        right: 0;
    }

    &__nav {
        display: flex;
        flex-direction: column;

        &-item {
            text-decoration: none;
            font-size: map_get($font-size, 16);
            font-weight: map_get($font-weight, bold);
            text-align: center;
            color: $color-secondary;
            margin-bottom: 20px;

            &:last-of-type {
                margin-bottom: 0;
            }

            &:hover {
                color: $color-primary--light;
            }
        }
    }

    &__btns {
        margin-top: 25px;
    }

    &__btn {
        max-width: 190px;
        margin-bottom: 10px;

        &:last-of-type {
            margin-bottom: 0;
        }
    }
}
