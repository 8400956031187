$color-white: #fff;

$color-primary: #01a8e8;
$color-tertiary: #ef1177;
$color-secondary: #34455a;

$color-primary--light: #02b9ff;

$color-grey-light: #e6e8ec;
$color-grey-primary: #c2c7ce;
$color-grey-secondary: #838486;

$footer-text-color: #9ac2d2;

$features-text-color: #abb5c0;
$category-text-color: #a8adb2;

$login-text-primary-color: #abb5c0;
$login-text-secondary-color: #a8adb2;

$main-text-color: #aee5fa;
$main-wrap-shadow-color: rgba(0, 91, 126, 0.5);
$main-wrap-deep-shadow-color: rgba(0, 0, 0, 0.35);
$body-bg-grey-color: #f5f6f7;
$mobile-bg-primary-color: linear-gradient(154deg, #01a8e8 2%, #03688e 98%);
$desktop-bg-primary-color: linear-gradient(98deg, #0298d1 5%, #03688e 92%);
;
