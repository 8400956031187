.home-nav {
    display: flex;
    margin-top: 10px;

    @media(min-width: $width-mobile-sm) {
        flex-direction: column;
        align-items: center;
    }

    @media(min-width: $width-tablet) {
        flex-direction: row;
        justify-content: space-between;
        max-width: 600px;
        margin: 20px auto 0;
    }


    &__item {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 4px 10px;
        opacity: 0.7;
        border-radius: 16px;
        border: solid 1px $color-grey-light;
        background-color: $color-white;
        width: 100%;
        cursor: pointer;
        text-decoration: none;
        transition: $transition;

        @media(min-width: $width-mobile-sm) {
            height: 50px;
            max-width: 340px;
            margin-bottom: 10px;
        }

        @media(min-width: $width-tablet) {
            height: 80px;
            max-width: 190px;
            margin-bottom: 0;
        }

        &:last-of-type {
            margin-bottom: 0;
        }

        &:hover {
            opacity: 1;
        }

        span {
            font-size: map_get($font-size, 16);
            font-weight: map_get($font-weight, semibold);
            color: $color-secondary;
            text-align: left;
        }
    }

    &__img {
        display: block;
        height: 40px;
        width: 40px;
        margin-right: 10px;
        min-height: 40px;
        min-width: 40px;
    }
}
