.pricing {
    @media(min-width: $width-mobile-sm) {
        padding: 20px;
    }

    @media(min-width: $width-mobile-l) {
        padding: 50px 20px;
    }

    @media(min-width: $width-desktop) {
        padding: 70px 0;
    }

    &__inner {
        padding-top: 40px;
    }

    &__amount,
    &__title,
    &__text {
        display: inline-block;
        width: 100%;
    }

    &__amount {
        font-size: map_get($font-size, 14);
        font-weight: map_get($font-weight, bold);
        text-align: left;

        @media(min-width: $width-mobile-sm) {
            margin: 0 0 20px;
        }

        @media(min-width: $width-tablet) {
            margin: 0 0 20px;
        }

        &--primary {
            color: $color-primary;
        }

        &--tertiary {
            color: $color-tertiary;
        }
    }

    &__title {
        text-align: left;
        font-size: map_get($font-size, 28);
        font-weight: map_get($font-weight, bold);
        color: $color-secondary;
        text-transform: uppercase;
    }

    &__text {
        font-weight: map_get($font-weight, semibold);
        color: $color-grey-primary;

        @media(min-width: $width-mobile-sm) {
            font-size: map_get($font-size, 12);
            max-width: 247px;
            margin-bottom: 10px;
        }
    }

    &__features {
        list-style: none;
        flex: 1 0 auto;

        &-item {
            display: flex;
            align-items: flex-start;
            width: 100%;
            margin-bottom: 10px;

            &:last-of-type {
                margin-bottom: 0;
            }
        }

        &-checkbox {
            padding-top: 5px;
            margin-right: 5px;

            img {
                display: block;
                width: 10px;
                height: 8px;
            }
        }

        &-desc {
            font-size: map_get($font-size, 13);
            font-weight: map_get($font-weight, semibold);
            color: $color-grey-secondary;
            text-align: left;
        }
    }

    &__btn {
        margin-top: 20px;
        width: 100%;
    }

    &__wrap {

        @media(min-width: $width-mobile-sm) {
            width: 100%;
            margin-bottom: 50px;
            padding: 25px 20px 20px;
        }

        @media(min-width: $width-tablet) {
            width: calc(50% - 10px);
            margin-bottom: 0;
            box-shadow: 0 30px 36px -10px $main-wrap-deep-shadow-color;
        }

        @media(min-width: $width-laptop) {
            max-width: 460px;
            min-height: 580px;
            padding: 40px 60px;
        }
    }

    &__items {
        display: flex;

        @media(min-width: $width-mobile-sm) {
            flex-direction: column;
        }

        @media(min-width: $width-mobile-sm) {
            margin: 20px auto 0;
        }

        @media(min-width: $width-tablet) {
            flex-direction: row;
            justify-content: space-between;
        }

        @media(min-width: $width-desktop) {
            max-width: 1024px;
            margin: 50px auto 0;
        }
    }
}
