.home {
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (min-width: $width-mobile-sm) {
        padding: 60px 20px 0;
    }

    @media(min-width: $width-tablet) {
        justify-content: center;
    }

    &__btn {
        margin-top: 20px;
    }

    &__title {
        font-weight: map_get($font-weight, semibold);
        color: $color-white;
        margin-top: 20px;
        letter-spacing: -0.05px;
        max-width: 530px;

        @media (min-width: $width-mobile-sm) {
            font-size: map_get($font-size, 18);
        }

        @media (min-width: $width-laptop) {
            font-size: map_get($font-size, 21);
        }

    }

    &__subtitle {
        text-align: center;
    }

    &__nav {
        width: 100%;

        @media (min-width: $width-mobile-sm) {
            padding-top: 10px;
        }

        @media (min-width: $width-mobile) {
            padding-top: 60px;
        }
    }
}
