.btn {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    margin: 0 auto;
    padding: 0 30px;
    border-radius: 30px;
    font-size: map_get($font-size, 12);
    font-weight: map_get($font-weight, bold);
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    transition: $transition;
    cursor: pointer;

    &.hidden {
        display: none;
    }

    @media(min-width: $width-mobile-sm) {
        max-width: 300px;
    }

    &--primary {
        background-color: $color-primary;
        color: $color-white;

        &:hover {
            opacity: .7;
        }
    }

    &--tertiary {
        background-color: $color-tertiary;
        color: $color-white;

        &:hover {
            opacity: .7;
        }
    }

    &--white {
        background-color: $color-white;
        color: $color-primary;

        &:hover {
            background-color: $color-primary;
            color: $color-white;
        }
    }

    &--transparent {
        background-color: transparent;
        color: $color-primary;
        border: 2px solid $color-primary;

        &:hover {
            background-color: $color-primary;
            color: $color-white;
        }
    }

    &:disabled {
        background-color: $color-grey-primary;
        color: $color-white;

        &:hover {
            background-color: $color-grey-primary;
            color: $color-white;
            opacity: 1;
            cursor: default;
        }
    }

    &--thin {
        height: 40px;
    }
}
