.legal {
    @media (min-width: $width-mobile-sm) {
        padding: 20px;
    }

    @media (min-width: $width-mobile-l) {
        padding: 50px 20px;
    }

    @media (min-width: $width-desktop) {
        padding: 70px 0;
    }

    &__wrap {
        margin-top: 50px;

        @media(min-width: $width-mobile-sm) {
            padding: 30px;
        }

        @media(min-width: $width-tablet) {
            box-shadow: 0 30px 36px -10px $main-wrap-deep-shadow-color;
        }

        @media(min-width: $width-laptop) {
            padding: 50px;
        }

        @media(min-width: $width-desktop) {
            padding: 80px;
        }
    }

    &__section {
        margin-bottom: 20px;
    }

    &__item {
        text-align: justify;
        font-weight: map-get($font-weight, semibold);
        color: $features-text-color;
        margin-bottom: 10px;
        font-size: map-get($font-size, 14);

        &:last-of-type {
            margin-bottom: 0;
        }

        &-inner {
            @media (min-width: $width-mobile-sm) {
                width: 100%;
            }

            @media (min-width: $width-tablet) {
                width: calc(50% - 15px);
            }
        }
    }

    &__title {
        font-weight: map-get($font-weight, bold);
        color: $color-secondary;
        text-transform: uppercase;
        margin: 10px 0;

        @media (min-width: $width-mobile-sm) {
            font-size: map-get($font-size, 16);
        }

        @media (min-width: $width-laptop) {
            font-size: map-get($font-size, 24);
        }

        @media (min-width: $width-desktop) {
            font-size: map-get($font-size, 32);
        }
    }

    ul.legal__list {
        list-style-type: disc;
        margin: 5px 0 10px 20px;

        li {
            text-align: justify;
            font-weight: map-get($font-weight, semibold);
            color: $features-text-color;
            margin-bottom: 5px;
            font-size: map-get($font-size, 14);

            &:last-of-type {
                margin-bottom: 0;
            }
        }
    }
}
