$global-font-family: 'SegoeUI';

$font-weight: (
        'normal': 400,
        'semibold': 600,
        'bold': 700,
);

$font-size-base-mobile: 10px;

$font-size: (
        8: 0.8rem,
        9: 0.9rem,
        10: 1rem,
        11: 1.1rem,
        12: 1.2rem,
        13: 1.3rem,
        14: 1.4rem,
        16: 1.6rem,
        18: 1.8rem,
        20: 2rem,
        21: 2.1rem,
        22: 2.4rem,
        24: 2.2rem,
        27: 2.7rem,
        28: 2.8rem,
        32: 3.2rem,
        36: 3.6rem,
        40: 4rem,
        50: 5rem,
        60: 6rem,
        66: 6.6rem,
        75: 7.5rem,
        90: 9rem,
);

@mixin font-face($font-family, $file-path, $weight: normal, $style: normal) {
    @font-face {
        font-family: $font-family;
        font-weight: $weight;
        font-style: $style;
        src: url('#{$file-path}.eot');
        src: url('#{$file-path}.eot?#iefix') format('embedded-opentype'),
        url('#{$file-path}.woff2') format('woff2'),
        url('#{$file-path}.woff') format('woff'),
        url('#{$file-path}.ttf') format('truetype');
    }
}

@include font-face('SegoeUI', '/fonts/segoe/SegoeUI', map_get($font-weight, normal));
@include font-face('SegoeUI', '/fonts/segoe/SegoeUI-SemiBold', map_get($font-weight, semibold));
@include font-face('SegoeUI', '/fonts/segoe/SegoeUI-Bold', map_get($font-weight, bold));

.heading {

    &--h1 {
        display: inline-block;
        width: 100%;
        font-weight: map_get($font-weight, bold);
        text-transform: uppercase;
        text-align: center;

        @media (min-width: $width-mobile-sm) {
            font-size: map_get($font-size, 18);
        }

        @media(min-width: $width-tablet) {
            font-size: map_get($font-size, 22);
        }

        @media(min-width: $width-laptop) {
            font-size: map_get($font-size, 27);
        }

        @media(min-width: $width-desktop) {
            font-size: map_get($font-size, 40);
        }
    }

    &--h2 {
        display: inline-block;
        width: 100%;
        font-weight: map_get($font-weight, bold);;
        line-height: 1.05;
        color: $color-primary--light;

        @media(min-width: $width-mobile-sm) {
            font-size: map_get($font-size, 50);
        }

        @media(min-width: $width-mobile) {
            font-size: map_get($font-size, 60);
        }

        @media(min-width: $width-tablet) {
            font-size: map_get($font-size, 75);
        }

        @media(min-width: $width-laptop) {
            font-size: map_get($font-size, 90);
        }
    }

    &--light {
        color: $color-white;
    }

    &--dark {
        color: $color-secondary;
    }
}

.bold {
    font-weight: map_get($font-weight, bold);

    &--primary {
        color: $color-primary;
    }

    &--secondary {
        color: $color-secondary;
    }

    &--white {
        color: $color-white;
    }
}
