.features {
    @media(min-width: $width-mobile-sm) {
        padding: 20px;
    }

    @media(min-width: $width-mobile-l) {
        padding: 50px 20px;
    }

    @media(min-width: $width-desktop) {
        padding: 70px 0;
    }

    &__item {
        display: flex;
        text-align: left;

        @media (min-width: $width-mobile-sm) {
            flex-direction: column;
            margin-bottom: 40px;
        }

        @media (min-width: $width-tablet) {
            flex-direction: row;
            justify-content: space-between;

            &:nth-child(2n) {
                flex-direction: row-reverse;
            }
        }

        @media (min-width: $width-laptop) {
            margin-bottom: 70px;
        }

        @media (min-width: $width-desktop) {
            margin-bottom: 140px;
        }

        &:last-of-type {
            margin-bottom: 0;
        }

        &-inner {
            @media (min-width: $width-mobile-sm) {
                width: 100%;
            }

            @media (min-width: $width-tablet) {
                width: calc(50% - 15px);
            }
        }
    }

    &__img {
        display: flex;
        justify-content: center;
        align-items: center;

        @media (min-width: $width-mobile-sm) {
            width: 100%;
        }

        @media (min-width: $width-tablet) {
            width: calc(50% - 15px);
        }

        img {
            display: block;
            width: 100%;
            height: auto;

            @media (min-width: $width-mobile-sm) {
                max-width: 100%;
            }

            @media (min-width: $width-mobile-l) {
                max-width: 400px;
            }

            @media (min-width: $width-tablet) {
                max-width: 70%;
            }
        }
    }

    &__title,
    &__desc {
        display: inline-block;
        width: 100%;
    }

    &__title {
        font-weight: map_get($font-weight, bold);
        color: $color-secondary;
        text-transform: uppercase;
        margin: 10px 0;

        @media(min-width: $width-mobile-sm) {
            font-size: map_get($font-size, 16);
        }

        @media(min-width: $width-laptop) {
            font-size: map_get($font-size, 24);
        }

        @media(min-width: $width-desktop) {
            font-size: map_get($font-size, 32);
        }
    }

    &__desc {
        font-weight: map_get($font-weight, semibold);
        color: $features-text-color;
        margin: 0 0 20px;

        @media(min-width: $width-mobile-sm) {
            font-size: map_get($font-size, 14);
        }

        @media(min-width: $width-laptop) {
            font-size: map_get($font-size, 16);
        }

        &:last-of-type {
            margin-bottom: 0;
        }
    }

    &__wrap {
        margin-top: 50px;

        @media(min-width: $width-mobile-sm) {
            padding: 25px 20px 20px;
        }

        @media(min-width: $width-tablet) {
            box-shadow: 0 30px 36px -10px $main-wrap-deep-shadow-color;
        }

        @media(min-width: $width-laptop) {
            padding: 100px;
        }

        @media(min-width: $width-desktop) {
            padding: 130px 120px;
        }
    }
}
