* {
    margin: 0;
    padding: 0;
}

body, html {
    font-family: SegoeUI, sans-serif;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    -webkit-font-smoothing: antialiased;

    @media(min-width: $width-mobile-sm) {
        font-size: $font-size-base-mobile;
    }
}

body {
    position: relative;

    @media(min-width: $width-mobile-sm) and (max-width: $width-laptop) {
        &:after {
            display: none;
            position: fixed;
            content: '';
            top: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.5);
        }

        &.mobile-menu-opened {
            overflow: hidden;

            &:after {
                display: block;
            }
        }
    }

    &.bg-primary {
        @media(min-width: $width-mobile-sm) {
            background: $mobile-bg-primary-color;
        }

        @media(min-width: $width-laptop) {
            background: $desktop-bg-primary-color;
        }
    }

    &.bg-grey-primary {
        @media(min-width: $width-mobile-sm) {
            background-color: $body-bg-grey-color;
        }

        @media(min-width: $width-laptop) {
            background-color: transparent;

            &:before, &:after {
                display: block;
                position: absolute;
                content: '';
                height: 100%;
                top: 0;
                width: 50%;
                z-index: -1;
            }

            &:before {
                left: 0;
                background-color: $body-bg-grey-color;
            }

            &:after {
                left: 50%;
                background: linear-gradient(137deg, #01a8e8 2%, #03688e 98%);
            }
        }
    }
}

section, header, footer, ul, div, a, input, button {
    box-sizing: border-box;
}

button {
    font-family: 'SegoeUI', sans-serif;
    border: none;
}

input::placeholder {
    font-family: 'SegoeUI', sans-serif;
    font-weight: map_get($font-weight, semibold);
    color: $color-grey-primary;

    @media(min-width: $width-mobile-sm) {
        font-size: map_get($font-size, 12);
    }

    @media(min-width: $width-mobile) {
        font-size: map_get($font-size, 14);
    }
}

input:focus {
    outline: none;
}

.main {
    min-height: 100vh;
    height: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media (min-width: $width-mobile-sm) {
        width: 100%;
        padding-top: 60px;
        text-align: center;
    }

    @media (min-width: $width-laptop) {
        max-width: 1024px;
    }

    @media (min-width: $width-desktop-sm) {
        max-width: 1120px;
    }

    @media (min-width: $width-desktop) {
        max-width: 1240px;
    }

    &__desc {
        display: inline-block;
        width: 100%;
        font-weight: map_get($font-weight, semibold);
        text-align: center;
        color: $main-text-color;

        @media(min-width: $width-mobile-sm) {
            max-width: 300px;
            margin: 5px auto;
            font-size: map_get($font-size, 12);
        }

        @media(min-width: $width-mobile-l) {
            font-size: map_get($font-size, 14);
        }

        @media(min-width: $width-tablet) {
            max-width: 530px;
        }

        @media(min-width: $width-desktop) {
            font-size: map_get($font-size, 20);
        }
    }

    &__wrap {
        display: flex;
        flex-direction: column;
        height: auto;
        border-radius: 14px;
        background-color: $color-white;
        position: relative;

        @media(min-width: $width-mobile-sm) {
            box-shadow: 0 4px 15px 0 $main-wrap-shadow-color;
        }

        &--bordered {
            border-radius: 16px;

            &:before {
                content: '';
                position: absolute;
                border-radius: 16px 16px 0 0;
                width: 100%;
                height: 16px;
                left: 0;
                top: 0;
            }
        }

        &--primary {

            &:before {
                background-color: $color-primary;
            }
        }

        &--tertiary {

            &:before {
                background-color: $color-tertiary;
            }
        }

        &:last-of-type {
            margin-bottom: 0;
        }
    }

    &__section {
        flex: 1 0 auto;
    }
}

a, input, textarea, button {
    outline: none;

    &:active {
        outline: none;
    }

    &:focus {
        outline: none;
    }
}
