.menu-burger {
    width: 28px;
    height: 23px;
    cursor: pointer;

    @media(min-width: $width-mobile) {
        display: block;
    }

    @media(min-width: $width-laptop) {
        display: none;
    }

    span {
        top: 10px;
        transition: all $menu-burger-transition ease-in-out;

        &,
        &:before,
        &:after {
            position: relative;
            display: block;
            height: 3px;
            background-color: $color-primary;
        }

        &:before,
        &:after {
            content: '';
            transition: all $menu-burger-transition ease-in-out;
        }

        &:before {
            top: -11px;
        }

        &:after {
            top: 8px;
            transition-delay: $transition-delay;
        }
    }

    &--is-active {
        span {
            width: 30px;
            transform: rotate(45deg) translateZ(0);

            &:before,
            &:after {
                width: 30px;
                left: 0;
                right: 0;
                top: 0;
                transform: rotate(-90deg) translateZ(0);
            }

            &:after {
                display: none;
            }
        }
    }
}
