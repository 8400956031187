.header {
    position: fixed;
    display: flex;
    align-items: center;
    height: 60px;
    left: 0;
    top: 0;
    width: 100vw;
    background-color: $color-white;
    border-bottom: 1px solid $color-grey-light;
    z-index: 12;

    @media(min-width: $width-mobile-sm) {
        padding: 15px 20px;
    }

    @media(min-width: $width-tablet) {
        padding: 0 20px;
    }

    &__inner {
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        max-width: 1240px;
        height: 100%;
        width: 100%;
    }

    &__logo {

        img {
            display: block;
            width: 30px;
            height: 30px;
        }

        a {
            display: flex;
            flex-direction: column;
            align-items: center;
            text-decoration: none;
        }

        @media(min-width: $width-laptop) {
            display: flex;
            width: 33.33%;
            align-items: flex-start;
        }

        &-span {

            @media(min-width: $width-mobile-sm) {
                display: none;
            }

            @media(min-width: $width-laptop) {
                display: inline-block;
                font-size: map_get($font-size, 14);
                font-weight: map_get($font-weight, semibold);
                text-transform: uppercase;

                &--primary {
                    color: $color-primary;
                }

                &--secondary {
                    color: $color-secondary;
                }
            }
        }
    }

    &__btns {

        @media(min-width: $width-mobile-sm) {
            display: none;
        }

        @media(min-width: $width-laptop) {
            display: flex;
            justify-content: flex-end;
            width: 33.33%;
        }

        .btn {

            @media(min-width: $width-laptop) {
                margin: 0 10px 0 0;
            }

            &:last-of-type {
                margin: 0;
            }
        }
    }
}
